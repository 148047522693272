.ButtonWithLabel {
    /* Frame 7591 */

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    cursor: pointer;

    /*position: absolute;*/
    width: 240px;
    height: 50px;

    background: #FFFFFF;
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    border-radius: 55px;
    margin: 0px 0 0 0px;
}

.ButtonFocus {
    background: rgba(105, 65, 198, 0.07);
    border: 1px solid #6941C6;
}

.ButtonDisable {
    background: #F9FAFB;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.Label {
    /* Label */

    /*position: static;*/
    width: 100%;
    height: 100%;

    /* Text sm/Medium */

    font-family: 'Inter', sans-serif;
    font-style: normal;
    /*font-weight: 500;*/
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Gray/700 */

    color: #344054;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 0;
}
