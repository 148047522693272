.chatTitle {
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-flow: row wrap;
    flex-grow: 0;
    flex-shrink: 0;
    align-items: normal;
    justify-content: flex-start;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: rgba(255, 255, 255, 0.92);
    flex-direction: column;
}
.content {
    /* Card header */
    display: flex;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    min-height: 1px;
    position: relative;
    padding: 18px 0;
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    margin-left: 0;
    right: auto;
    left: auto;
    text-align: center;
    color: rgb(24, 144, 255);
    overflow-x: hidden;
}

.avatarCard {
    /* Avatar */

    display: flex;
    flex-direction: column;
    padding: 0;

    position: static;
    width: 40px;
    height: 40px;
    left: 0;
    top: 0;
    border-radius: 200px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 20px;
}

.avatarChat {
    position: static;
    width: 50px;
    height: 50px;
    left: 0;
    top: 0;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 10px;
}
.textContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    align-content: space-between;
    padding: 0;
    width: 750px;
    height: 48px;
    left: 72px;
    top: 4px;
}

.text {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #101828;
    padding-left: 20px;
    margin-top: 10px;
}

.chatCardContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    cursor: pointer;
}

.chatCardTitle {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    font-weight: 500;
    padding-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
}

.unreadDot {
    margin-top: 5px;
    float: left;
    display: inline-block;
    background: #9E77ED;
    border-radius: 5px;
    width: 10px;
    height: 10px;
}

.chatCardSubtitleText {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #667085;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    width: 75%;
}

.onlineDot {
    position: absolute;
    width: 10px;
    height: 10px;
    right: 0;
    bottom: 0;
    background: #12B76A;
    border: 2px solid #FFFFFF;
    border-radius: 5px;
}

.chatCardSubtitle {
    width: 100%;
}

.AlertContainer {
    /* Auto layout */
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-self: center;
    padding: 16px;

    /* Primary/25 */
    background: #FCFAFF;
    /* Primary/300 */
    border: 1px solid #D6BBFB;
    border-radius: 8px;
    margin-left: 10%;
    margin-right: 10%;
}

.AlertText {
    /* Text and supporting text */
    margin: 0 12px;
    /* Text sm/Medium */
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */
    /* Primary/700 */
    color: #6941C6;
}