.Pending {
    background: #F2F4F7;
    border-radius: 16px;
    padding: 2px 8px 2px 6px;
    gap: 6px;
    color: #344054;

}

.Rejected {
    background: #FEF3F2;
    border-radius: 16px;
    padding: 2px 8px 2px 6px;
    gap: 6px;
    color: #B42318;
}

.Accepted {
    background: #ECFDF3;
    border-radius: 16px;
    padding: 2px 8px 2px 6px;
    gap: 6px;
    color: #027A48;
}

