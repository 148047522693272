.MainContainer {
    /* Main */

    /* Auto layout */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 20px 48px;

    position: static;
    top: 65px;

    /* Gray/25 */
    background: #FCFCFD;
}

.Header {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
}

.Title {
    position: static;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    color: #101828;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px 0;
}

.Subtitle {
    position: static;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #667085;
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 8px 0;
}

.CheckMessage {
    /* Go to Chat */
    /* Text sm/Medium */
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #6F29DC;
    border: none;
    background: none;
    cursor: pointer;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}