.SignIn {
    /* Sign in */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.Content {
    margin-top: 5%;
}

.Header {
    /* Header */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;

    position: static;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin-bottom: 5%;
}

.Logo {
    /* Group 5 */

    position: static;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.TextBox {
    /* Text and supporting text */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    position: static;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0 0;
}

.Title {
    /* Text */

    position: static;

    /* Display sm/Semibold */

    font-family: sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 38px;
    /* identical to box height, or 127% */

    text-align: center;

    /* Gray/900 */

    color: #101828;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 0 0;
}

.Support {
    /* Supporting text */

    position: static;

    /* Text md/Normal */

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    text-align: center;

    /* Gray/500 */

    color: #667085;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0 0;
}

.Form {
    /* Content */

    /* Auto layout */

    /*display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    padding: 0;
    height: 100%;

    !* Inside auto layout *!

    order: 1;
    margin-bottom: 5%;*/
}

.Details {
    /* Form */

    /* Auto layout */

/*    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    position: static;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;


    !* Inside auto layout *!

    flex: none;
    order: 0;*/
}

.RowForgot {
    /* Row */

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}

.Actions {
    /* Actions */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    position: static;
    width: 100%;


    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
}

.SignInButton {
    /* _Button base */

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;
    cursor: pointer;

    position: static;
    width: 100%;
    height: 44px;

    font-family: 'Inter', sans-serif;
    color: #FFFFFF;
    background: #800080;
    border: 1px solid #800080;
    box-sizing: border-box;
    /* Shadow/xs */

    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
}

.RowSignUp {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5%;
}

.Text {
    /* Text */

    position: static;
    margin-right: 2%;

    /* Text sm/Normal */

    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    /* Gray/500 */

    color: #667085;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.GoToSignUpButton {
    /* Button */

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    margin-left: 2%;

    position: static;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.TextOnlyButton {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    /* Text */

    position: static;

    /* Text sm/Medium */

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #6941C6;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}