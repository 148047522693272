.Box {
    /* Frame 7594 */

    /* Auto layout */

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 60%;

    background: #FFFFFF;
    box-shadow: 0 -2px 37px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
}

.TextTitle {
    /* TextTitle */

    position: static;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #344054;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0;
}