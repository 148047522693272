.InputWithLabel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    position: static;
    /*width: 100%;*/
    height: 70px;
    left: 0;
    top: 0;

    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
    margin: 20px 6px 6px;

}

.label {
    /* Label */

    position: static;
    /*width: 95px;*/
    height: 20px;
    left: 0;
    top: 0;

    /* Text sm/Medium */

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Gray/700 */

    color: #344054;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 0;
}

.InputContainer {
    /* Input */

    /* Auto layout */
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 10px 14px;

    position: static;
    width: 100%;
    height: 44px;
    left: 0;
    top: 26px;

    /* Base/White */

    background: #FFFFFF;
    /* Gray/300 */
    border: 1px solid #D0D5DD;
    box-sizing: border-box;
    /* Shadow/xs */

    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0 0;
}

.InputError {
    border: 1px solid #FDA29B;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.InputDisable {
    background: #F9FAFB;
    border: 1px solid #D0D5DD;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
}

.InputFocus {

    border: 1px solid #D6BBFB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #F4EBFF;
    border-radius: 8px;

}

.ErrorMsg {
    /* Hint text */

    display: flex;
    position: static;
    /*width: 100%;*/
    height: 20px;
    left: 0;
    top: 76px;

    /* Text sm/Normal */

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Error/500 */

    color: #F04438;


    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 3px 0 0 15px;

}

::placeholder {
    color: #C9C9CA;
}

