.CreateAMatch {
    text-align: center;
    display: flex;
    flex-direction: column;
    /*justify-content: center;*/
    /*align-items:center;*/
    /*align-content: center;*/
    position: relative;
    margin-top: 30px;
    margin-left: 0;

    /* Base/White */

    background: #FFFFFF;
}

.ShareContainer {
    margin: 2% 23%;
}

.Button {
    /* _Button base */

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 10px 18px;
    cursor: pointer;

    position: static;
    height: 50px;

    font-family: 'Inter', sans-serif;
    color: #FFFFFF;
    background: #6941C6;
    border: 1px solid #6941C6;
    box-sizing: border-box;
    /* Shadow/xs */

    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 1;
    margin: 16px 42%;
}

.Arrow {
    position: fixed;
    /*width: 20%;*/
    /*height: 1%;*/
    left: 20%;
    bottom: 10%;

    transform: rotate(-10deg);
}

.ShareButton {
    display: flex;
    position: center;
    flex-direction: row;
    justify-content: space-between;
}

.ShareButtonText {
    /* Share with <Clip Name> */


    position: static;
    width: 186px;
    height: 24px;
    left: 15px;
    top: 15px;

    /* Text md/Medium */

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #344054;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 4px;
}

.StartClip {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
}