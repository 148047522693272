.Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 689px;
    height: 468px;
    background-color: #FFFFFF;
    border-radius: 24px;
    box-shadow: 0 4px 151px rgba(0, 0, 0, 0.25);
}

.Button {
    /* _Button base */

    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;

    bottom: 20%;

    font-family: 'Inter', sans-serif;
    color: #FFFFFF;
    background: #6941C6;
    border: 1px solid #6941C6;
    box-sizing: border-box;
    /* Shadow/xs */

    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    /*flex-grow: 1;*/
    margin: 0 0;
}

.TextTitle {
    /* TextTitle */

    position: static;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #344054;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 30px 0;
}

.TextBody {
    position: static;
    width: 503px;
    height: 34px;
    left: 0;
    top: 31px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #344054;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 12px 0 20px 0;
}

.Image {
    /* Group 11 */
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    position: static;
    height: 160px;
    margin: 0;
}

