.Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    height: 301px;
    background-color: #FFFFFF;
    border-radius: 24px;
    padding: 15px;
}

.Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 18px;

    width: 352px;
    height: 44px;

    background: #7F56D9;

    border: 1px solid #7F56D9;

    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;

    cursor: pointer;
}

.ButtonText {

    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;

    color: #FFFFFF;
}

.TextTitle {
    /* TextTitle */

    position: static;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 24px;
    text-align: center;

    color: #344054;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 20px 0;
}

.TextBody {

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 550;
    font-size: 18px;
    line-height: 20px;
    text-align: center;

    color: #667085;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0 0 20px 0;
}

.ButtonsContainer {

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;



    /* Inside auto layout */

    flex: none;
    order: 2;
    flex-grow: 0;
}