.SignUp {
    /* Sign up */

    /* Auto layout */

    display: flex;
    flex-direction: row;
    /*align-items: center;*/
    padding: 0;

    position: relative;
    height: 100vh;
    width: 100vw;
    /* Base/White */

    background: #FFFFFF;
}

.WelcomeSection {
    background: #150C36;
    flex: 1;
}

.DetailsSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    flex: 1;
}

.WelcomeText {
    /* Container */

    /* Auto layout */

    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-left: 5%;
    margin-top: 10%;
}

.Stars {
    /* Stars */

    /*position: static;*/
    width: 80px;
    height: 80px;
    left: 96px;

    /* Inside auto layout */

    /*flex: none;*/
    /*order: 0;*/
    /*flex-grow: 0;*/
    /*margin: 20% 0 0;*/
}

.TextBox {
    /* Text and supporting text */

    display: flex;
    flex-direction: column;
    margin: 5% 0;
}

.Title {
    /* Text */

    display: flex;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 60px;
    line-height: 72px;
    /* identical to box height, or 120% */

    letter-spacing: -0.02em;

    /* Base/White */

    color: #FFFFFF;
}

.Support {
    /* Supporting text */

    text-align: left;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    /* or 156% */

    /* Gray/200 */

    color: #EAECF0;

    margin-top: 5%;
}

.Arrow {
    /* Hand-drawn arrow */

    position: fixed;
    width: 200px;
    height: 50px;
    left: 43%;
    bottom: 40%;

    transform: rotate(-10deg);
}

.Content {
    margin-top: 5%;
}

.Logo {
    display: flex;
}

.Form {
    /* Content */

    /* Auto layout */

    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*padding: 0;*/
    /**/
    /*position: static;*/
    /*width: 360px;*/
    /*height: 628px;*/

    /* Inside auto layout */

    /*flex: none;*/
    /*order: 0;*/
    /*align-self: stretch;*/
    /*flex-grow: 0;*/
    /*margin: 5% 0;*/
}

.Details {
    /* SignInForm */

    /* Auto layout */

    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*padding: 0;*/

    /*position: static;*/
    /*width: 100%;*/
    /*left: 0;*/
    /*top: 0;*/


    /* Inside auto layout */

    /*flex: none;*/
    /*order: 0;*/
    /*align-self: stretch;*/
    /*flex-grow: 0;*/
    /*margin: 5% 0;*/
}

.Actions {
    /* Actions */

    /* Auto layout */

    /*display: flex;*/
    /*flex-direction: column;*/
    /*align-items: flex-start;*/
    /*padding: 0;*/
    /**/
    /*position: static;*/
    /*width: 100%;*/
    /*left: 0;*/
    /*top: 228px;*/


    /* Inside auto layout */

    /*flex: none;*/
    /*order: 1;*/
    /*align-self: stretch;*/
    /*flex-grow: 0;*/
    /*margin: 5% 0;*/
}

.SignUpButton {
    /* _Button base */

    /* Auto layout */

    /*display: flex;*/
    /*flex-direction: row;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*padding: 10px 18px;*/
    cursor: pointer;

    /*position: static;*/
    width: 98%;
    height: 44px;

    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #FFFFFF;
    background: #800080;
    border: 1px solid #800080;
    box-sizing: border-box;
    /* Shadow/xs */

    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    margin: 6px;
    /*margin-right: 2%;*/
}

.UploadImgButton {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    display: block;
}

.RowSignIn {
    display: flex;
    align-items: flex-start;
    margin-top: 5%;
}

.Text {
    /* Text */

    position: static;

    /* Text sm/Normal */

    font-family: sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */


    /* Gray/500 */

    color: #667085;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0 10px;
}

.GoToSignInButton {
    /* Button */

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;

    position: static;
    width: 51px;
    height: 20px;
    left: 234px;
    top: 0;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 0 4px;
}

.TextOnlyButton {
    background:none;
    border:none;
    margin:0;
    padding:0;
    cursor: pointer;
    /* Text */

    position: static;

    /* Text sm/Medium */

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height, or 143% */

    color: #6941C6;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}