
.Box {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 689px;
    background-color: #FFFFFF;
    border-radius: 24px;
    padding-bottom: 10px;
}

.Button {
    background-color: transparent;
    border-color: transparent;
    cursor: pointer;
}

.CloseButton {
    width: 10%;
    background: #667085;
    border: 1px solid #344054;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    cursor: pointer;
    font-family: 'Inter',sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #FFFFFF;
}

.TextTitle {
    /* TextTitle */

    position: static;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #344054;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 30px 0;
}

.TextBody {
    position: static;
    width: 503px;
    height: 34px;
    left: 0;
    top: 31px;

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    color: #344054;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 20px 0 30px 0;
}

.Image {
    /* Group 11 */

    display: flex;
    position: static;
    height: 160px;
    align-content: center;
    margin: 0;
}

.ButtonsContainer {

    /* Auto layout */

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;



    /* Inside auto layout */

    flex: none;
    order: 3;
    flex-grow: 0;
}

.Counter {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: space-between;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    order: 2;
}
