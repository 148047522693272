.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

/*------------------------------- chat css -------------------------------*/

.ce-settings-column {
  width: 0 !important;
  max-width: 0 !important;
}

.ce-chat-feed-column {
  width: 75% !important;
  max-width: 75% !important;
  flex: 0 0 75% !important;
}

.ql-container.ql-snow {
  border: none !important;
}

.ql-toolbar.ql-snow {
  border: none !important;
}

.ce-quill-container {
  background: #FFFFFF !important;
  border: 1px solid #D0D5DD !important;
  box-sizing: border-box !important;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

#ce-send-message-button {
  background: #7F56D9 !important;
  border: 1px solid #7F56D9 !important;
  box-sizing: border-box !important;
  box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05) !important;
  border-radius: 8px !important;
}

#ce-connecting-popup {
  background-color: #7F56D9 !important;
  border: 1px solid #7F56D9 !important;
}

.ce-chat-card.ce-active-chat-card {
  background-color: #F9F5FF !important;
  border: none !important;
  padding: 16px !important;
}

.ce-chat-unread-dot {
  background: #9E77ED !important;
  border-radius: 5px !important;
  width: 10px !important;
  height: 10px !important;
}

.ce-chat-card {
  font-family: 'Inter', sans-serif !important;
  font-style: normal !important;
}

.ce-message-bubble.ce-my-message-bubble {
  background: #7F56D9 !important;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06) !important;
  border-radius: 8px 0 8px 8px !important;
}

.ce-message-timestamp {
  color: #150C36 !important;
}

.ce-message-and-date {
  margin-right: 5px !important;
  margin-left: 5px !important;
}

.ce-login-fail-breaker {
  display: flex !important;
  justify-content: center !important;
}

.ce-chat-title-container {
  color: #6F29DC !important;
}

.ce-avatar {
  background-color: #FFFFFF !important;
  background-size: 0 !important;
  width: 0 !important;
  height: 0 !important;
}

.ce-avatar-text {
  padding-top: 0 !important;
  font-size: 0 !important;
}

/**
 * ==============================================
 * Dot Falling
 * ==============================================
 */
.dot-falling {
  position: relative;
  left: -9999px;
  width: 10px;
  height: 10px;
  border-radius: 0 8px 8px 8px;
  background-color: #F2F4F7;
  color: #667085;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);;
  animation: dotFalling 1s infinite linear;
  animation-delay: .1s;
}

.dot-falling::before, .dot-falling::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 0;
}

.dot-falling::before {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #667085;
  color: #667085;
  animation: dotFallingBefore 1s infinite linear;
  animation-delay: 0s;
}

.dot-falling::after {
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #667085;
  color: #667085;
  animation: dotFallingAfter 1s infinite linear;
  animation-delay: .2s;
}
