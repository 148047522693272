
.CreateAClipBusiness {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF url('../../Assets/Background-business.svg') no-repeat fixed;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.CreateAClipRomance {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF url('../../Assets/Background-romantic.svg') no-repeat fixed;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Header {
    margin-top: 20px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px;
}

.Title {
    position: static;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #344054;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 8px 0px;
}

.Description {
    position: static;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #344054;
    order: 1;
    flex-grow: 0;
    margin: 8px 0px;
}

.Body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 50%;
    background: #FFFFFF;
    box-shadow: 0px 30px 105px rgba(0, 0, 0, 0.06);
    border-radius: 17px;
    margin-bottom: 50px;
}

.Container {
    margin: 3%;
}

.Toggle {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    padding: 4px;
    background: #F9FAFB;
    border: 1px solid #F2F4F7;
    box-sizing: border-box;
    border-radius: 8px;
}

.NameForm {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-self: center;

}

.InputContainer {
    width: 100%;
    margin-right: 3%;
    margin-left: 3%;
}

.Line {
    width: 94%;
    height: 1px;
    background: #D0D5DD;
}

.ClipThemButtonRomance {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #C641A9;
    border: 1px solid #C641A9;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    cursor: pointer;
}

.ClipThemButtonBusiness {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    position: static;
    left: 0%;
    right: 0%;
    top: 0%;
    bottom: 0%;
    background: #6941C6;
    border: 1px solid #6941C6;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 0px;
    cursor: pointer;
}

.ClipThemButtonText {
    position: static;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 8px;
}

.BodyBottom {
    flex: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.ScoreTextBusiness {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #6941C6;
}

.ScoreTextRomance {
    position: relative;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #C641A9;
}