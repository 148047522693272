
.ToolBarContainer {
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.ClipLogoContainer {
    margin-top: 6px;
    flex: 1;
}

.ToolBarButtonsFocus {
    border: 1px solid #D6BBFB;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05), 0 0 0 4px #F4EBFF;
    border-radius: 8px;
}



.ToolBarButton {
    /* Text md/Medium */

    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */


    /* Gray/700 */

    color: #344054;
    text-transform: none;

}


